import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="NotFound">
          <h1>Cette page n'existe plus 😞</h1>
          <Link to="/">Retourner sur la page principale</Link>
        </div>
      )
}

export default NotFound;