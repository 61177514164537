import './Contact.scss';
import emailLogo from '../assets/mail.svg';
import twitterLogo from '../assets/twitterLogo.svg';

const Contact = () => {
  return(
    <div className="Contact">
      <div className="ContactDiv">
        <div className="Mail">
          <img className="Email-logo" src={emailLogo} alt="Mail"/>
        </div>
        <p>Une énigme à partager? Une piste d'amélioration?</p>
        <a href="mailto:charles.steimberg@gmail.com,sasha.bronner@yahoo.com?subject=Questions 1 jour 1 énigme" >
          <p>Contactez-nous</p>
        </a>
      </div>
      <div className="ContactDiv">
        <div className="Mail">
          <img className="Twitter-logo" src={twitterLogo} alt="Twitter"/>
        </div>
        <p>Besoin d'actu énigme et des meilleurs scores?</p>
        <a href="https://twitter.com/1jour1enigmefr" target="_blank">
          <p>Suivez-nous</p>
        </a>
      </div>
    </div>
  )
};

export default Contact;