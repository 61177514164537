/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      time
      riddleId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      time
      riddleId
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      time
      riddleId
      name
      createdAt
      updatedAt
    }
  }
`;
export const createRiddle = /* GraphQL */ `
  mutation CreateRiddle(
    $input: CreateRiddleInput!
    $condition: ModelRiddleConditionInput
  ) {
    createRiddle(input: $input, condition: $condition) {
      id
      riddle
      answer
      maxTries
      createdAt
      updatedAt
    }
  }
`;
export const updateRiddle = /* GraphQL */ `
  mutation UpdateRiddle(
    $input: UpdateRiddleInput!
    $condition: ModelRiddleConditionInput
  ) {
    updateRiddle(input: $input, condition: $condition) {
      id
      riddle
      answer
      maxTries
      createdAt
      updatedAt
    }
  }
`;
export const deleteRiddle = /* GraphQL */ `
  mutation DeleteRiddle(
    $input: DeleteRiddleInput!
    $condition: ModelRiddleConditionInput
  ) {
    deleteRiddle(input: $input, condition: $condition) {
      id
      riddle
      answer
      maxTries
      createdAt
      updatedAt
    }
  }
`;
