import './Leaderboard.scss';

const Leaderboard = ({scores}) => {

  const sortByScore = scores.sort(function(a, b) { return a.time - b.time });
  const topTen = sortByScore.slice(0,10);

  return(
    <div className="Leaderboard">
        <div className="TableHeader">
            <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
                <tr>
                  <th>Pseudo</th>
                  <th>Score</th>
                </tr>
            </thead>
            </table>
        </div>
        <div className="TableContent">
            <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
                {topTen.map((item, i) => (
                    <tr key={i}>
                        <td className="RankCell">{i + 1}</td>
                        <td className="UsernameCell">{item.name}</td>
                        <td className="ScoreCell">
                          {("0" + Math.floor(((item.time  / 3600000) + 9.5) % 24)).slice(-2)}:
                          {("0" + Math.floor(((item.time / 60000) +30) % 60)).slice(-2)}:
                          {("0" + Math.floor((item.time / 1000) % 60)).slice(-2)}.
                          {("00" + ((item.time) % 1000)).slice(-3)}
                        </td>
                    </tr>))}
            </tbody>
            </table>
        </div>
    </div>
  )
};

export default Leaderboard;