import './SideBar.scss';
import { useState, useEffect, useRef } from 'react';
import FriendInfo from './FriendInfo';
import LogIn from './LogIn';
import SignIn from './SignIn';
import Leaderboard from './Leaderboard';
import { API } from 'aws-amplify';
import { getRiddle } from '../graphql/queries';
import { listScores } from '../graphql/queries'

const SideBar = ({dateProps}) => {

  const [connected, setConnected] = useState({status: false, currentWindow: 0});
  const [navClicked, setnavClicked] = useState(false);
  const [riddle, setRiddle] = useState("");
  const [answer, setAnswer] = useState([]);
  const [scores, setScores] = useState([]);
  const startX = useRef(null);
  const endX = useRef(null);
  const handleClick = () => setnavClicked(!navClicked);
  const logIn = () => setConnected({status: connected.status, currentWindow: 1});
  const signIn = () => setConnected({status: connected.status, currentWindow: 2});
  const closeWindow = () => setConnected({status: connected.status, currentWindow: 0});
  const setConnection = () => setConnected({status: true, currentWindow: 0});
  
  useEffect(() => {
    fetchRiddle();
    fetchScores();
  }, [])

  const props = {
    setConnection : setConnection,
    closeWindow : closeWindow
  }

  const renderConnectionWindow = () => {
    switch(connected.currentWindow) {
      case 1:
        return <LogIn props={props}/>
      case 2:
        return <SignIn props={props}/> 
      default:
        return <></>
    }
  }
  
  const fetchScores = async () => {
    try {
      let filter = {riddleId: {eq:dateProps.day}};
      const data = await API.graphql({ query: listScores, variables: { filter: filter , limit: 1000}});
      const scoresData = data.data.listScores.items;
      setScores(scoresData)
    } catch (err) { console.log('error fetching scores') }
  }

  const fetchRiddle = async () => {
    try {
      const riddleData = await API.graphql({query: getRiddle, variables: { id: dateProps.day - 1 }});
      setRiddle(riddleData.data.getRiddle.riddle);
      setAnswer(riddleData.data.getRiddle.answer.split(";"));
    } catch (err) { console.log('error getting riddle:', err) }
  }

  const renderConnection = () => {
    if (connected.status){
      return <FriendInfo/>
    } else {
      return(
        <div className="Connection">
          <p>Connectez-vous pour voir si vos amis ont réussi à répondre à l'énigme</p>
          <div className="ButtonPart">
            <button className="LogIn" onClick={logIn}>Connexion</button>
            <button className="SignUp" onClick={signIn}>Inscription</button>
          </div>
        </div>
      )
    }
  }

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  }

  const handleTouchMove = (e) => {
    endX.current = e.touches[0].clientX;
  }

  const handleTouchEnd = () => {
    if (startX.current === null || endX.current === null) {
      return;
    }

    const threshold = 50;

    if (startX.current - endX.current > threshold) {
      setnavClicked(false);
    } else if (endX.current - startX.current > threshold) {
      setnavClicked(true);
    }

    startX.current = null;
    endX.current = null;
  }

  const renderSolved = () => {
    if (scores.length === 0){
      return <div className="DailyResult"><p>Personne n'a encore trouvé la solution.</p> </div>
    } else if (scores.length === 1){
      return(
        <div className="DailyResult">
        <p>Enigme élucidée par {scores.length} futé:</p>
        <div className= "Leaderboard">
          <Leaderboard scores={scores}/>
        </div>
      </div>
      )
    } else {
      return(
        <div className="DailyResult">
        <p>Enigme élucidée par {scores.length} futés.</p>
        <div className= "Leaderboard">
          <p>Joueurs les plus rapides:</p>
          <Leaderboard scores={scores}/>
        </div>
      </div>
      )
    }
  }

  return(
    <div className = {navClicked ? "SideBar active" : "SideBar"} 
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}>
      <div className="Hamburger" onClick={handleClick}>
        <span></span>
        <span></span>
      </div>
      <h4>Jour #{dateProps.day}</h4>
      <span className="Divider"></span>
      {renderSolved()}
      <span className="Divider"></span>
      <div className="Solution">
        <p>Enigme de la veille: {riddle}<br/><br/>La réponse était <span className="AllAnswer"><span className="HiddenAnswer">...</span><span className="Answer">{answer[0]}.</span></span></p>
      </div>
    </div>
  )
};

export default SideBar;