import './LeftTries.scss';

const LeftTries = ({props}) => {

  const handleBubble = (bubbleId) => {
    if (bubbleId - props.tryCount < 0) return(1)
  };

  const divs = Array(props.maxTries).fill(null);

  const renderLeftTries = () => {
    if(props.maxTries > 0){
      return <p>Essais restants: </p>
    }
  }

  return(
    <div className="LeftTries">
        {renderLeftTries()}
        {divs.map((_, i) => (
          <div key={i} className="Bubble" animation={handleBubble(i)}/>
        ))}
    </div>
  )
};

export default LeftTries;