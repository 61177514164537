import './Riddle.scss';
import confetti from 'canvas-confetti';
import stringSimilarity from 'string-similarity';
import {useState, useEffect} from 'react';
import question from '../assets/question-circle.svg';
import { API, graphqlOperation } from 'aws-amplify';
import { createScore } from '../graphql/mutations';
import { getRiddle } from '../graphql/queries';
import { listScores } from '../graphql/queries'
import LeftTries from './LeftTries';

const Riddle = ({dateProps}) => {
  const [solved, setSolved] = useState(false);
  const [proposition, setProposition] = useState("");
  const [tryCount, setTryCount] = useState(0);
  const [maxTries, setMaxTries] = useState(0);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [riddle, setRiddle] = useState("");
  const [answer, setAnswer] = useState([]);
  const [score, setScore] = useState(0);
  const [scores, setScores] = useState([]);
  const [wrong, setWrong] = useState(0);

  useEffect(() => {
    fetchRiddle();
    fetchScores();
    const storedCount = localStorage.getItem(dateProps.day + "_count");
    if (storedCount) {
      setTryCount(parseInt(storedCount));
    }
  }, [])

  useEffect(() => {
    const storedName = localStorage.getItem(dateProps.day + "_name");
    if (storedName && scores.length !==0) {
      const storedScore = localStorage.getItem(dateProps.day + "_score");
      setScore(storedScore);
      setSolved(true);
      setName(storedName);
    }
  }, [scores])

  useEffect(() => {
    const wrongAnimation = async () => {
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      await sleep(1000);
      setWrong(0);
    }
    wrongAnimation();
  }, [tryCount])

  const props = {
    maxTries: maxTries,
    tryCount: tryCount
  }

  const fetchRiddle = async () => {
    try {
      const riddleData = await API.graphql({query: getRiddle, variables: { id: dateProps.day }});
      setRiddle(riddleData.data.getRiddle.riddle);
      setAnswer(riddleData.data.getRiddle.answer.split(";"));
      if(riddleData.data.getRiddle.maxTries) setMaxTries(riddleData.data.getRiddle.maxTries);
    } catch (err) { console.log('error getting riddle:', err) }
  }

  const fetchScores = async () => {
    try {
      let filter = {riddleId: {eq:dateProps.day}};
      const data = await API.graphql({ query: listScores, variables: { filter: filter, limit: 1000}});
      const scoresData = data.data.listScores.items;
      setScores(scoresData)
    } catch (err) { console.log('error fetching scores') }
  }

  const addScore = async () => {
    try {
      const input = { name: name, riddleId: dateProps.day,  time: score};
      await API.graphql(graphqlOperation(createScore, {input: input}))
    } catch (err) {
      console.log('error creating score:', err)
    }
  }

  const validateRiddle = () => {
    if (proposition.length > 25) setError("Votre proposition ne peut excéder 25 caractères");
    else if (proposition.length === 0) setError("Votre proposition doit contenir au moins 1 caractère");
    else {
      var success = false;
      answer.map(item => { if(stringSimilarity.compareTwoStrings(proposition.toLowerCase(), item.toLowerCase()) > 0.85) success = true});
      if (success){
        const timeNow = new Date();
        const startDayRiddle = new Date(dateProps.startDay);
        startDayRiddle.setDate(startDayRiddle.getDate() + dateProps.day);
        setError("");
        setScore(timeNow.getTime() - startDayRiddle.getTime())
        fetchScores();
      } else {
        setError("");
        setProposition("");
        localStorage.setItem(dateProps.day + "_count", tryCount + 1);
        setTryCount(tryCount + 1);
        setWrong(1);
      }
    }
  }

  const validateName = () => {
    if (name.length > 16) setError("Votre pseudo ne peut excéder 16 caractères");
    else if (name.length < 3) setError("Votre pseudo doit contenir au moins 3 caractères");
    else if (name.includes(" ")) setError("Votre pseudo ne peut contenir d'espace");
    else {
      setError("");
      addScore();
      setSolved(true);
      localStorage.setItem(dateProps.day + "_name", name);
      localStorage.setItem(dateProps.day + "_score", score);
    }
  }

  const handleKeyPress = (e) => {if (e.key === "Enter") validateRiddle()};
  const handleChange = (e) => setProposition(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleNameKeyPress = (e) => {if (e.key === "Enter") validateName()};

  useEffect(() => {
    if(score !== 0){
      confetti({
        particleCount: 200,
        spread: 70,
        origin: { y: 0.6 }
      });
    }
  }, [score]);

  const getRanking = () => {
    var rank = 1;
    scores.map(item => { if (item.time < score) rank = rank + 1});
    switch (rank) {
      case 1:
        return("premier·ère ! La crème de la crème 🥇");
      case 2:
        return("deuxième ! Sherlock Holmes n'a qu'à bien se tenir 🥈");
      case 3:
        return("troisième ! Quelle perspicacité 🥉");
      default:
        return(rank + "ème.");
    }
  }

  const renderRiddle = () => {
    if (tryCount >= maxTries && maxTries !== 0){
      return(
        <div className="Congrats">
        <h2>Ce n'était pas la bonne réponse.<br/><br/>
        <span>Vous ferez mieux la prochaine fois.<br/>Revenez demain pour une nouvelle énigme et découvrir la réponse à l'énigme du jour !</span>
        </h2>
        </div>
      )
    } else if (score===0){
      return(
        <div className="MainSpace">
          <div className="RiddleText">
            <p>{riddle}</p>
          </div>
          <div className="RiddleForm" animation={wrong}>
            <div className="RiddleInput">
              <input type="text" onKeyDown={handleKeyPress} onChange={handleChange} className="FormInput" id="input" placeholder="Votre suggestion" required="" autoComplete="off" autoCorrect="off" value={proposition}/>
              <label htmlFor="input" className="FormLabel">Votre suggestion</label>
            </div>
            <button className="MainButton" onClick={validateRiddle}><span>Valider la réponse</span></button>
          </div>
          <span className="ErrorMessage">{error}</span>
          <LeftTries props={props}/>
        </div>
      )
    } else if (solved){
      return(
        <div className="Congrats">
          <h2>Félicitations ! <br/><br/><span>{name}, vous êtes {getRanking()} Rendez-vous demain 9h30 pour une nouvelle énigme.</span></h2>
          <p></p>
        </div>
      )
    }
    else{
      return(
        <div className="Congrats">
          <h2>Félicitations ! <br/><br/><span>Rendez-vous demain 9h30 pour une nouvelle énigme.</span></h2>
          <div className="NameForm">
            <div className="RiddleInput">
              <input type="text" onKeyDown={handleNameKeyPress} onChange={handleNameChange} className="FormInput" id="input" placeholder="Pseudo" required="" autoComplete="off" autoCorrect="off" value={name}/>
              <label htmlFor="input" className="FormLabel">Pseudo</label>
            </div>
            <button className="MainButton" onClick={validateName}><span>Valider</span></button>
          </div>
          <span className="ErrorMessage">{error}</span>
        </div>
      )
    }
  }

  return(
    <div className="Riddle">
      <div className="QuestionTooltip">
        <span className="TooltipText">1jour1enigme.fr, c'est une nouvelle énigme tous les matins à 9h30.</span>
        <img className="QuestionLogo" src={question} alt="Question"/>
      </div>
      {renderRiddle()}
    </div>
  )
};

export default Riddle;