import './App.scss';
import { Component } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import HomePage from './components/HomePage';
import NotFound from './components/NotFound';


class App extends Component {
  render(){
    return(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/404" element={<NotFound/>} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App;